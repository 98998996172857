<template>
   <div class="emb-payment-wrap">
		<!-- <emb-page-title heading="Información de pago y envío"></emb-page-title> -->
		<!-- <div class="emb-payment-content section-gap overflow-x-hidden"> -->
			<v-container grid-list-xl py-0>
				<h3 class="text-center mt-7">FINALIZAR PEDIDO</h3>
				<template v-if="cart && cart.length > 0">
					<!-- <emb-sidebar-panel class="mb-2"></emb-sidebar-panel> -->
					<shipping-address></shipping-address>
				</template>
				<template v-else>
					<div class="text-center mb-14">
						<div class="mb-6">
							<img  alt="cart-empty" height="128" src="/static/images/empty-cart.png" width="128">
						</div>
						<h4 > Tu bolsa de compras está vacía.</h4>
						<v-btn :to="{name: 'menu'}" color="primary">Ir a la carta</v-btn>
					</div>
				</template>
			</v-container>
		<!-- </div> -->
   </div>
</template>

<script>
import ShippingAddress from './Component/ShippingAddress'
// import PromoCodes from './Component/PromoCodes'
// import PaymentOption from './Component/PaymentOption'
import { mapGetters } from 'vuex';

export default {
	data(){
		return{
			stepOneFormValid: false,
			expansionPanel: [0]
		}
	},
	components:{
		shippingAddress:ShippingAddress,
		// promoCodes:PromoCodes,
		// paymentOption:PaymentOption
	},
	created() {
		// this.appendCulqiScript()
	},
	computed: {
		...mapGetters(["cart"]),
		panel: {
			get() {
				return this.expansionPanel;
			},
			set(val) {
				if(val.includes(0)){
					this.expansionPanel = [0];
					this.stepOneFormValid = false;
				}else if(val.includes(1) && this.expansionPanel != 1){
					this.expansionPanel = [1];
				}else if(val.includes(2)){
					this.expansionPanel = [2];
				}else{
					this.expansionPanel= [];
				}
			}
		}
	},
	methods: {
		appendCulqiScript () {
			return new Promise(() => {
				if (!document.getElementById('culqui-lib')) {

					let culqiScript = document.createElement('script');
					culqiScript.setAttribute('src', 'https://checkout.culqi.com/js/v3');
					culqiScript.setAttribute('id', 'culqui-lib');
					document.body.appendChild(culqiScript);

				}
			});
		},
		enableStepOneForm(){
			this.stepOneFormValid = true;
			this.expansionPanel = [1];
		},
		changePanelValue(){
			this.expansionPanel = [2];
		}
	}
}
</script>
