<template>
	<div>
		<v-row>
			<v-col cols="12" xl="6" lg="12" md="12" sm="12">
				<v-card dark color="secondary" class="pb-4">
					<v-card-text>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12 text-center>
								<h6>¿A quién le estamos entregando?</h6>
							</v-flex>
						</v-layout>
						<v-row dense>
							<v-col cols="12" xl="12">
								<v-text-field
									type="email"
									v-model="orderData.shipping.contact.name"
									label="Nombre completo"
									autocomplete="off"
									outlined
									dense
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" xl="6" lg="6" md="12" sm="12">
								<v-text-field
									type="email"
									v-model="orderData.shipping.contact.email"
									label="Correo electrónico"
									autocomplete="off"
									outlined
									dense
								>
								</v-text-field>
							</v-col>
							<v-col cols="12" xl="6" lg="6" md="12" sm="12">
								<v-text-field
									type="text"
									v-model="orderData.shipping.contact.telephone"
									label="Telefono"
									autocomplete="off"
									outlined
									dense
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12 text-center>
								<h6>¿Dónde debemos entregarlo?</h6>
							</v-flex>
						</v-layout>
						<v-row v-if="orderData.shipping.location">
							<v-col cols="12" xl="12" lg="12" md="12">
								<v-text-field
									type="text"
									v-model="orderData.shipping.location.address"
									label="Dirección de entrega"
									outlined
									dense
									readonly
								>
								</v-text-field>
							</v-col>
						</v-row>
						<v-layout row wrap>
							<!-- <v-flex xl12 lg12 xs12 sm12 md12>
								<label>
									Dirección de entrega
									<a href="#" class="error--text" @click.prevent="showLocationDialog = true">[+ Cambiar]</a>
									<el-input
										v-model="orderData.shipping.location.address" 
										required
										readonly
									></el-input>
								</label>
							</v-flex> -->
							<v-flex xl6 lg12 xs12 sm12 md12>
								<v-text-field
									type="text"
									v-model="orderData.shipping.reference"
									label="Rerefencia"
									autocomplete="off"
									outlined
									dense
									placeholder="Ejemplo: 2 cuadras antes del parque"
								>
								</v-text-field>
							</v-flex>
							<v-flex xl6 lg12 xs12 sm12 md12>
								<v-text-field
									type="text"
									v-model="orderData.shipping.inside_number"
									label="Número interior"
									autocomplete="off"
									outlined
									dense
									placeholder="Ejemplo: Depto 24, Int 3B, sin número"
								>
								</v-text-field>
							</v-flex>
						</v-layout>
						<v-flex lg12 xl12 xs12 sm12 md12 text-center>
							<h6 class="mt-3">Comprobante de pago</h6>
							<v-divider></v-divider>
						</v-flex>
						<v-layout row wrap>
							<v-flex xl12 lg12 xs12 sm12 md12 mt-4>
								<el-radio
									v-model="orderData.billing.document_type_id"
									v-for="(doc, index) in document_types"
									:key="index"
									:label="doc.id"
									@change="changeDocumentType"
									class="font-weight-regular"
								>
									{{ doc.description }}
								</el-radio>
							</v-flex>
						</v-layout>
						<v-layout row wrap v-if="orderData.billing.document_type_id">
							<v-flex xl5 lg6 xs12 sm12 md12>
								<label
									>{{
										orderData.billing.document_type_id == '03'
											? 'DNI (opcional)'
											: 'RUC'
									}}
									<el-input
										v-model="orderData.billing.customer_number"
										required
										autocomplete="off"
									></el-input>
								</label>
								<!-- <small class="error--text" v-if="errors && errors.billing.customer_number" v-text="errors.billing.customer_number[0]"></small> -->
							</v-flex>
							<v-flex xl7 lg6 xs12 sm12 md12>
								<label>
									{{
										orderData.billing.document_type_id == '03'
											? 'Nombre (opcional)'
											: 'Razón social'
									}}
									<el-input
										v-model="orderData.billing.customer_name"
										required
										autocomplete="off"
									></el-input>
								</label>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12 text-center>
								<h6>Opciones de pago</h6>
								<v-divider></v-divider>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12>
								<el-radio
									v-for="(row, index) in payment_method_types"
									:key="index"
									:label="row.id"
									v-model="orderData.payment_method_type_id"
									@change="changePaymentMethod"
									class="font-weight-regular"
									:disabled="index == 1 ? true : false"
								>
									{{ row.name.toUpperCase() }}
								</el-radio>
							</v-flex>
						</v-layout>
						<v-layout row wrap v-if="orderData.payment_method_type_id == 2">
							<v-flex lg12 xl12 xs12 sm12 md12>
								<label>
									Ingresa el monto con el que pagará
									<el-input-number
										v-model="orderData.billing.amount"
										:precision="2"
										:step="0.1"
										controls-position="right"
									>
									</el-input-number>
								</label>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12>
								<v-checkbox v-model="checkTerms">
									<template v-slot:label>
										<div>
											He leído las
											<v-tooltip bottom>
												<template v-slot:activator="{ on }">
													<a
														target="_blank"
														href="/term-condition"
														@click.stop
														v-on="on"
													>
														Condiciones de uso.
													</a>
													&
													<a
														target="_blank"
														href="/privacy-policy"
														@click.stop
														v-on="on"
													>
														Políticas de privacidad
													</a>
												</template>
												Se abrirá en una nueva pestaña
											</v-tooltip>
										</div>
									</template>
								</v-checkbox>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex lg12 xl6 xs12 sm12 md12>
								<el-button
									type="error"
									round
									class="btn-block"
									@click.prevent="goToMenu()"
									>Seguir comprando</el-button
								>
							</v-flex>
							<v-flex lg12 xl6 xs12 sm12 md12>
								<el-button
									type="primary"
									round
									class="btn-block"
									:disabled="!checkTerms || loadingSubmit"
									@click.prevent="makePayment()"
								>
									<template v-if="isPaymentOnline">
										Pagar
									</template>
									<template v-else>
										Finalizar pedido
									</template>
								</el-button>
							</v-flex>
						</v-layout>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" xl="6" lg="12" md="12" sm="12">
				<v-card dark color="secondary">
					<v-card-text class="text-center">
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12>
								<h5>Resumen de pedido</h5>
								<v-divider></v-divider>
							</v-flex>
						</v-layout>
						<v-layout row wrap>
							<v-flex lg12 xl12 xs12 sm12 md12>
								<v-simple-table>
									<template v-slot:default>
										<thead>
											<tr>
												<th>Producto</th>
												<th class="text-right" width="25%">Cantidad</th>
												<th class="text-right">Total</th>
												<th class="text-center">Acciones</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(item, index) in cart" :key="index">
												<template v-if="currentIndex != index">
													<td class="text-left">{{ item.name }}</td>
													<td class="text-right">
														<el-input-number
															v-model="item.quantity"
															:min="1"
															:max="10"
															@input="calculateItemTotal(index)"
															controls-position="right"
															:precision="0"
															:step="1"
															size="mini"
														>
														</el-input-number>
													</td>
													<td class="text-right">
														<emb-currency-sign></emb-currency-sign>
														{{ item.total }}
													</td>
													<td class="text-center align-middle">
														<v-btn
															fab
															dark
															x-small
															color="accent"
															@click="getCurrentIndex(index)"
														>
															<v-icon dark>mdi-settings</v-icon>
														</v-btn>
													</td>
												</template>
												<template v-else>
													<td
														colspan="3"
														class="text-right align-middle pl-0 pr-1"
													>
														<el-input
															v-model="item.note"
															size="mini"
															placeholder="Nota para el chef"
														></el-input>
													</td>
													<td class="text-center align-middle pa-0">
														<v-btn
															class="mr-1"
															fab
															dark
															x-small
															color="error"
															@click="deleteProductFromCart(cart)"
														>
															<v-icon dark>mdi-delete</v-icon>
														</v-btn>
														<v-btn
															fab
															dark
															x-small
															color="success"
															@click="clearCurrentIndex"
														>
															<v-icon dark>mdi-check-all</v-icon>
														</v-btn>
													</td>
												</template>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-flex>
							<v-flex lg12 xl12 xs12 sm12 md12>
								<v-divider class="my-4"></v-divider>
								<div class="d-flex align-center justify-space-between">
									<h5 class="ml-4">TOTAL</h5>
									<h5 class="mr-7">
										<emb-currency-sign></emb-currency-sign>
										{{ orderData.total }}
									</h5>
								</div>
							</v-flex>
						</v-layout>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:isRouteMenu="true"
			:lat="lat"
			:lng="lng"
			:draggable="true"
			:autoAdd="false"
			:item="null"
		>
		</emb-location-dialog>
		<emb-delete-confirmation
			ref="deleteConfirmationDialog"
			message="¿Eliminar?"
			@onConfirm="onDeleteProductFromCart"
		>
		</emb-delete-confirmation>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import round from 'lodash/round';

export default {
	props: ['changeStepOneForm'],
	data() {
		return {
			resource: 'user/orders',
			document_types: [],
			payment_method_types: [],
			loadingSubmit: false,
			valid: false,
			checkTerms: false,
			isPaymentOnline: false,
			errors: null,
			lat: null,
			lng: null,
			form: {},
			showLocationDialog: false,
			currentIndex: null,
			selectDeletedProduct: null,
		};
	},
	computed: {
		...mapGetters(['cart', 'shipping', 'orderData', 'location', 'loggedUser']),
	},
	async created() {
		this.orderData.shipping.location = this.location;
		this.orderData.shipping.contact.name = this.loggedUser.name;
		this.orderData.shipping.contact.email = this.loggedUser.email;
		this.orderData.shipping.contact.telephone = this.loggedUser.phone;
		await this.calculateTotal();
		await this.getStaticTables();
		// await this.appendCulqiScript()
		// await this.initForm()

		this.lat = this.location.latitude;
		this.lng = this.location.longitude;
	},
	methods: {
		...mapActions({
			changeQuantityHandler: 'changeQuantityHandler',
			onClearOrderData: 'onClearOrderData',
			addNotification: 'addNotification',
			removeLocation: 'removeLocation',
		}),
		initForm() {
			this.orderData.total = 0;
			this.orderData.items = [];
			this.orderData.shipping.contact.name = null;
			this.orderData.shipping.contact.email = null;
			this.orderData.shipping.contact.telephone = null;
			this.orderData.billing.voucher = false;
			this.orderData.billing.document_type_id = null;
			this.orderData.billing.amount = 0;
			this.orderData.billing.customer_number = null;
			this.orderData.billing.customer_name = null;
		},
		calculateItemTotal(index) {
			let item = this.cart[index];
			let total = round(item.unit_price * item.quantity, 2);

			this.cart[index].total = total;

			this.changeQuantityHandler(item);
			this.calculateTotal();
		},
		calculateTotal() {
			let total = 0;
			if (this.cart) {
				for (const item of this.cart) {
					total += round(item.unit_price * item.quantity, 2);
				}
			}
			this.orderData.total = total;
		},
		getStaticTables() {
			this.$http.get(`${this.resource}/static-tables`).then((response) => {
				this.document_types = response.data.document_types;
				this.payment_method_types = response.data.payment_method_types;
			});
		},
		changeDocumentType() {
			this.orderData.billing.voucher = true;
		},
		changePaymentMethod() {
			if (this.orderData.payment_method_type_id == 3) {
				this.isPaymentOnline = true;
			} else {
				this.isPaymentOnline = false;
			}
		},
		makePayment() {
			if (this.isPaymentOnline) {
				this.paymentByCulqi();
			} else {
				this.sendOrder();
			}
		},
		async sendOrder() {
			this.orderData.items = this.cart;
			this.orderData.user_id = this.loggedUser.id;
			if (!this.orderData.shipping.contact.name) {
				return this.addNotification({
					show: true,
					type: 'error',
					message: 'Ingresa su nombre completo',
				});
			}
			if (!this.orderData.shipping.contact.email) {
				return this.addNotification({
					show: true,
					type: 'error',
					message: 'Ingresa su correo electrónico',
				});
			}
			if (!this.orderData.shipping.contact.telephone) {
				return this.addNotification({
					show: true,
					type: 'error',
					message: 'Ingresa su correo electrónico',
				});
			}
			if (
				this.orderData.payment_method_type_id == 2 &&
				this.orderData.billing.amount < this.orderData.total
			) {
				return this.addNotification({
					show: true,
					type: 'error',
					message: 'El monto con el que pagará en menor al precio total',
				});
			}

			if (
				this.orderData.billing.document_type_id == '01' &&
				this.orderData.billing.customer_number.length !== 11
			) {
				return this.addNotification({
					show: true,
					type: 'error',
					message: 'RUC inválido, vuelva a ingresar',
				});
			}

			this.loadingSubmit = true;
			await this.$http
				.post(`/${this.resource}`, this.orderData)
				.then((response) => {
					if (response.data.success) {
						this.loadingSubmit = false;
						this.$message.success(response.data.message);
						this.onClearOrderData();
						this.initForm();
						// this.removeLocation()
						this.$router.push({
							name: 'FinalReceipt',
							params: { id: response.data.data.id },
						});
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message;
					} else {
						this.$message.error(error.response.data.message);
						console.log(error);
					}
					this.loadingSubmit = false;
				})
				.then(() => {
					this.loadingSubmit = false;
				});
		},
		appendCulqiScript() {
			return new Promise(() => {
				if (!document.getElementById('culqui-lib')) {
					let culqiScript = document.createElement('script');
					culqiScript.setAttribute('src', 'https://checkout.culqi.com/js/v3');
					culqiScript.setAttribute('id', 'culqui-lib');
					document.body.appendChild(culqiScript);
				}
			});
		},
		paymentByCulqi() {
			window.Culqi.publicKey = 'pk_test_5seNezWzk3IjtApy';
			window.Culqi.options({
				lang: 'auto',
				// modal: false,
				installments: false,
				// customButton: 'Donar',
				style: {
					logo: 'https://culqi.com/LogoCulqi.png',
					maincolor: '#0ec1c1',
					buttontext: '#ffffff',
					maintext: '#4A4A4A',
					desctext: '#4A4A4A',
				},
			});
			window.Culqi.settings({
				title: 'Sislii Store',
				currency: 'PEN',
				description: 'Polo/remera Culqi lover',
				amount: 3500,
			});
			window.Culqi.open();
		},
		goToMenu() {
			this.$router.push({ name: 'menu' });
		},
		addUserDetails() {
			this.$refs.form.validate();
			if (this.valid == true) {
				this.$emit('changeStepOneForm');
				this.$store.dispatch('addUserDetails', this.userInfo);
			}
		},
		getCurrentIndex(index) {
			this.currentIndex = index;
		},
		clearCurrentIndex() {
			this.currentIndex = null;
		},
		deleteProductFromCart(product) {
			this.$refs.deleteConfirmationDialog.openDialog();
			this.selectDeletedProduct = product;
		},
		onDeleteProductFromCart() {
			this.$refs.deleteConfirmationDialog.close();
			this.addNotification({
				show: true,
				type: 'error',
				message: 'El producto ha sido eliminado del carrito',
			}).then(() => {
				this.calculateTotal();
			});

			this.$store.dispatch('onDeleteProductFromCart', this.selectDeletedProduct);
		},
	},
};
</script>
